@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  font-family: "Inter", system-ui;
}
.agent article > div:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #E2E8F0;
  margin-bottom: 20px;
}
.agent article > div > *:last-child {
  margin-bottom: 0 !important;
}
.agent article + article {
  margin-bottom: 1rem;
}
.agent h2 {
  color: var(--slate-900, #0F172A);
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 8px;
  letter-spacing: 0.1px;
}
.agent ul {
  list-style: disc;
  margin-bottom: 20px;
  padding-left: 20px;
}
.agent h3 {
  color: var(--slate-900, #0F172A);
  font-size: 14px;
  font-weight: 600;
  line-height: 175%;
  letter-spacing: 0.07px;
  margin-bottom: 4px;
}
.agent ul + h3 {
  margin-top: 16px;
}
.agent h3 + ul {
 margin-bottom: 0;
}
.agent li, .agent p, .agent .main-content {
  color: var(--slate-800, #1E293B);
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0.07px;
}
.agent p {
  margin-bottom: 20px;
}
.agent li {
  margin-bottom: 4px;
}
.agent li:last-child {
  margin-bottom: 0;
}
.radio {
  accent-color: #6A47F2
}
.agent a {
  color: #2563EB;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
.agent a:hover {
  color: #188ef6;
  text-decoration: underline;
}
.agent > *:last-child {
  margin-bottom: 0;
}
.visible-area::-webkit-scrollbar {
  display: none;
}
.visible-area {
  -ms-overflow-style: none;
  position: relative;
  scrollbar-width: none;
}
.visible-area.show::after  {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 75.53%);
  height: 85px;
  width: 100%;
  bottom: 78px;
  left: 0;
  position: fixed;
  content: '';
}
.visible-area.show > .flex:first-child {
  margin-top: 16px;
}
.visible-area
.agent strong {
  font-weight: 600;
}
.main-screen::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100px;
  background: linear-gradient(white 8%, transparent);
  top: 0;
  left: 0;
}
.logo {
  z-index: 1;
}
.tta--gradient--text {
  background: linear-gradient(90deg, #2378FA 0%, #B469E7 46.76%, #F58E33 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tta-input-form {
  background-image: linear-gradient(90deg, #F5CB94, #D6D3E9, #99D2EC);
  transition: all .3s;
}
.tta-input-form:hover {
  transition: all .3s;

  background-image: linear-gradient(90deg, #e2b984, #9a92d4, #72bcde);
}
.tta-input-form textarea {
  border: none !important;;
}
.tta-input-form input::placeholder, .tta-input-form input::-ms-input-placeholder {
  color: #64748B;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .agent li, .agent p, .agent a, .agent .main-content {
    font-size: 14px;
    line-height: 170%;
  }
  .agent a {
    line-height: 24px;
  }
  .agent ul {
    padding-left: 20px;
  }
  .agent p, .agent ul, .agent .main-content {
    margin-bottom: 14px;
  }
  .agent li {
    margin-bottom: 6px;
  }
  .agent h2 {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 1.2;
  }
  .main-screen::after {
    top: 0px;
  }
  .visible-area.show::after {
    display: none;
  }
}
.question-item:last-child > div {
  border-bottom: 0;
  padding-bottom: 0;
}
.balls {
  width: 1.7em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  margin-left: 2px;
}
.color-p p, .color-p svg path {
  transition: all .3s;
}
.color-p:hover p, .color-p:hover span, .color-p:hover svg path {
  color: #6947F3;
  stroke: #6947F3;
  transition: all .3s;
}
.balls div {
  width: 0.4em;
  height: 0.4em;
  border-radius: 50%;
  background-color: #6947F3;
  transform: translateY(-100%);
  animation: wave 0.8s ease-in-out alternate infinite;
}

.balls div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.balls div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}
.bg-design {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}

.fade-up-div {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.5s ease forwards;
}
.fade-up-div {
  animation-delay: 0.2s;
}
.fade-up-div.second {
  animation-delay: 0.4s;
}
.fade-up-div.third {
  animation-delay: 0.6s;
}
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.test-mode-tip {
  z-index: 9999;
  animation: fadeDown 1s ease forwards;
}
.test-mode-tip::after {
  background: linear-gradient(transparent, #FACC15);
  width: 3px;
  height: 50px;
  top: -50px;
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
}
@keyframes fadeDown {
  from {
    opacity: .5;
    transform: translateY(-80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.tour-box {
  bottom: 2px;
  left: calc(50% - 523px);
  transform: translateX(-50%);
  z-index: 999999;
}